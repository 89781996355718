import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Icon from '@mdi/react';
import { mdiBilliards } from '@mdi/js'; // Example icon that can represent Keno
import { mdiSlotMachine } from '@mdi/js';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'; // MUI Game Icon

function Sidebar({ onUsersListClick, onSlotDetailClick, onKenoDetailClick, onGameOnboardingClick }) {
    const activeStyle = {
        backgroundColor: '#D77D00', // Change this to your desired color
        color: '#000', // Adjust text color as needed for contrast
    };

    const role = localStorage.getItem('role');
    const navigate = useNavigate();

    const getRoleLabel = (role) => {
        switch (role) {
            case 'STORE':
                return 'Player List';
            case 'SUB_DISTRIBUTOR':
                return 'Store List';
            case 'DISTRIBUTOR':
                return 'Sub Distributor List';
            case 'MASTER':
                return 'Distributor List';
            default:
                return '';
        }
    };

    const handleBrandClick = () => {
        if (role === 'ADMIN') {
            navigate('/admin-dashboard');
        } else {
            navigate('/');
        }
        closeSidebarOnMobile(); // Close sidebar on mobile or tablet
    };

    // Function to close sidebar in mobile and tablet views
    const closeSidebarOnMobile = () => {
        if (window.innerWidth <= 1024) { // Adjust this value if needed for specific breakpoints
            const hamburgerIcon = document.querySelector('.hamburger');
            if (hamburgerIcon) {
                hamburgerIcon.click();
            }
        }
    };

    const handleUsersListClick = (e) => {
        e.preventDefault();
        onUsersListClick();
        navigate('/user-list');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };

    const handleSlotDetailClick = (e) => {
        e.preventDefault();
        onSlotDetailClick();
        navigate('/slot-detail');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };

    const handleKenoDetailClick = (e) => {
        e.preventDefault();
        onKenoDetailClick();
        navigate('/keno-detail');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };

    const handleGameOnboardingClick = (e) => {
        e.preventDefault();
        onGameOnboardingClick();
        navigate('/game-onboarding');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };

    const handleBroadcastMessageClick = (e) => {
        e.preventDefault();
        onGameOnboardingClick();
        navigate('/broadcast-message');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };

    const handleVentureResponseClick = (e) => {
        e.preventDefault();
        navigate('/ventureresponse');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };

    const handleDailyBonusWheelClick = (e) => {
        e.preventDefault();
        navigate('/daily-bonus-wheel');
        closeSidebarOnMobile(); // Close sidebar if on mobile or tablet
    };



    return (
        <nav id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar">
                <div className="sidebar-brand" onClick={handleBrandClick} style={{ cursor: 'pointer' }}>
                    <i className="fas fa-dice align-middle" style={{ marginRight: '10px' }}></i>
                    <span className="align-middle">Millionaire Club</span>
                </div>

                <ul className="sidebar-nav">
                    {role === 'ADMIN' ? (
                        <>
                            <li className="sidebar-header">
                                Pages
                            </li>
                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/admin-dashboard"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                >
                                    <i className="align-middle" data-feather="sliders"></i> <span className="align-middle">Admin Dashboard</span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/slot-detail"
                                    style={({ isActive }) => (isActive ? activeStyle : undefined)}
                                    onClick={(e) => { handleSlotDetailClick(e); }} // Close sidebar on mobile or tablet
                                >
                                    <Icon path={mdiSlotMachine} size={1} /> {/* Slot Machine Icon */}
                                    <span className="align-middle">Slot Detail</span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/keno-detail"
                                    style={({ isActive }) => (isActive ? activeStyle : undefined)}
                                    onClick={(e) => { handleKenoDetailClick(e); }} // Close sidebar on mobile or tablet
                                >
                                    <Icon path={mdiBilliards} size={1} /> {/* Keno Icon */}
                                    <span className="align-middle">Keno Detail</span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/game-onboarding"
                                    style={({ isActive }) => (isActive ? activeStyle : undefined)}
                                    onClick={(e) => { handleGameOnboardingClick(e); }} // Close sidebar on mobile or tablet
                                >
                                    <SportsEsportsIcon style={{ marginRight: '5px' }} /> {/* Game Controller Icon */}
                                    <span className="align-middle">Game Onboarding</span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/broadcast-message"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={(e) => { handleBroadcastMessageClick(e); }} // Close sidebar on mobile or tablet
                                >
                                    <i className="fa-solid fa-comment"></i> <span className="align-middle">Dashboard Message</span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/ventureresponse"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={handleVentureResponseClick} // Attach the new function here
                                >
                                    <i className="fa-solid fa-reply-all"></i> <span className="align-middle">Venture Response</span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/daily-bonus-wheel"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={handleDailyBonusWheelClick} // Attach the new function here
                                >
                                    <i className="fa-solid fa-arrows-spin"></i> <span className="align-middle">Daily Bonus Wheel</span>
                                </NavLink>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="sidebar-header">
                                Pages
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/"
                                    end
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                >
                                    <i className="align-middle" data-feather="sliders"></i> <span className="align-middle">Dashboard</span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/user-list"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={handleUsersListClick}
                                >
                                    <i className="fas fa-users align-middle"></i> <span className="align-middle">{getRoleLabel(role)}</span>
                                </NavLink>
                            </li>

                            <li className="sidebar-header">
                                Records
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/recharge"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                >
                                    <i className="fas fa-coins align-middle"></i> <span className="align-middle">Recharge Records</span>
                                </NavLink>
                            </li>

                            <li className="sidebar-item">
                                <NavLink
                                    className="sidebar-link"
                                    to="/redeem"
                                    style={({ isActive }) => isActive ? activeStyle : undefined}
                                    onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                >
                                    <i className="fas fa-hand-holding-usd align-middle"></i><span className="align-middle">Redeem Records</span>
                                </NavLink>
                            </li>

                            {role === 'STORE' && (
                                <li className="sidebar-item">
                                    <NavLink
                                        className="sidebar-link"
                                        to="/gamelist"
                                        style={({ isActive }) => isActive ? activeStyle : undefined}
                                        onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                    >
                                        <i className="fas fa-gamepad align-middle"></i> <span className="align-middle">Game Records</span>
                                    </NavLink>

                                    <NavLink
                                        className="sidebar-link"
                                        to="/store-Daily-Bonus"
                                        style={({ isActive }) => isActive ? activeStyle : undefined}
                                        onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                    >
                                        <i class="fa-solid fa-circle-dollar-to-slot"></i> <span className="align-middle">Daily Bonus</span>
                                    </NavLink>
                                </li>
                            )}

                            {role === 'MASTER' && (
                                <>
                                    <li className="sidebar-header">
                                        Others
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            className="sidebar-link"
                                            to="/subdistributor"
                                            style={({ isActive }) => isActive ? activeStyle : undefined}
                                            onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                        >
                                            <i className="fas fa-users-cog align-middle"></i> <span className="align-middle">Sub Distributor List</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            className="sidebar-link"
                                            to="/store"
                                            style={({ isActive }) => isActive ? activeStyle : undefined}
                                            onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                        >
                                            <i className="fas fa-users-cog align-middle"></i> <span className="align-middle">Store List</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            className="sidebar-link"
                                            to="/players"
                                            style={({ isActive }) => isActive ? activeStyle : undefined}
                                            onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                        >
                                            <i className="fas fa-users-cog align-middle"></i> <span className="align-middle">Player List</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            className="sidebar-link"
                                            to="/games"
                                            style={({ isActive }) => isActive ? activeStyle : undefined}
                                            onClick={closeSidebarOnMobile} // Close sidebar on mobile or tablet
                                        >
                                            <i className="fas fa-gamepad align-middle"></i> <span className="align-middle">Game List</span>
                                        </NavLink>
                                    </li>

                                </>
                            )}
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;
