import React from 'react';

function Footer() {
    const currentYear = new Date().getFullYear(); // Dynamically get the current year

    return (
        <footer className="footer">
            <div className="container-fluid" style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: '0.9em', color: 'grey' }}>
                © {currentYear} Millionaire Club. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
