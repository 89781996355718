import React, { useState, useCallback } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { Button, styled, TextField, Box, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const SearchButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
}));

const ResetButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#d32f2f',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#9a0007',
    },
}));

const StoreDailyBonus = () => {
    const [rows, setRows] = useState([]);
    const [userId, setUserId] = useState('');
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchError, setSearchError] = useState(false); // Error state for search validation
    const navigate = useNavigate();

    const columns = [
        { field: 'userId', headerName: 'User ID', flex: 1, minWidth: 70 },
        { field: 'username', headerName: 'Username', flex: 1, minWidth: 100 },
        { field: 'spinBalanceSum', headerName: 'Spin Balance Sum', flex: 1, minWidth: 150 },
        { field: 'wheelSpinCount', headerName: 'Wheel Spin Count', flex: 1, minWidth: 150 },
    ];

    const handleSearch = useCallback(async () => {
        if (!userId && !username) {
            setSearchError(true);
            return;
        }

        setIsLoading(true);
        setSearchError(false);

        const searchParams = [];
        if (userId) searchParams.push({ key: 'userId', value: userId });
        if (username) searchParams.push({ key: 'username', value: username });

        const payload = { searchParams };

        try {
            const response = await api.post('/v1/admin/user/players-bonus', payload);

            if (response.data.success) {
                if (response.data.data.length === 0) {
                    toast.info("No data found for the given search criteria.");
                    setRows([]);
                } else {
                    const updatedRows = response.data.data.map((row) => ({
                        ...row,
                        id: row.userId,
                    }));
                    setRows(updatedRows);
                }
            } else {
                toast.error("Failed to fetch data. Please try again later.");
            }
        } catch (error) {
            if (error.response?.status === 403) {
                handle403Error(navigate);
            } else {
                toast.error("Failed to fetch data. Please try again later.");
            }
        } finally {
            setIsLoading(false);
        }
    }, [userId, username, navigate]);

    const handleReset = () => {
        setUserId('');
        setUsername('');
        setRows([]);
        setSearchError(false);
    };

    return (
        <div>
            <ToastContainer />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" component="h1" fontWeight="bold">Daily Bonus</Typography>
                </div>
            </div>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretch', sm: 'center' }}
                marginBottom={2}
            >
                <TextField
                    label="User ID"
                    variant="outlined"
                    size="small"
                    value={userId}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                            setUserId(value);
                            setSearchError(false);
                        }
                    }}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                    error={searchError && !userId}
                />
                <Box display="flex" alignItems="center" marginRight={2}>
                    <Typography variant="body1">OR</Typography>
                </Box>

                <TextField
                    label="Username"
                    variant="outlined"
                    size="small"
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setSearchError(false);
                    }}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                    error={searchError && !username}
                />
                <SearchButton onClick={handleSearch} disabled={isLoading}>
                    {isLoading ? 'Searching...' : 'Search'}
                </SearchButton>
                <ResetButton onClick={handleReset}>
                    Reset
                </ResetButton>
            </Box>
            {searchError && (
                <Typography color="error" variant="body2" style={{ marginBottom: '8px' }}>
                    Please provide either a User ID or Username to search.
                </Typography>
            )}

            <div style={{ width: '100%' }}>
                <DataTable rows={rows} columns={columns} />
            </div>
        </div>
    );
};

export default StoreDailyBonus;
