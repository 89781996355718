import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import forge from "node-forge";
import ReCAPTCHA from "react-google-recaptcha";
import { WebSocketContext } from '../utils/WebSocketContext';
import { handle403Error } from '../utils/handle403Error';
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

// Public key and recaptcha site key
const publicKeyPem = process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/g, '\n');
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const encrypt = (publicKeyPem, message) => {
    try {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const encrypted = publicKey.encrypt(message, "RSA-OAEP", {
            md: forge.md.sha256.create(),
        });
        return forge.util.encode64(encrypted);
    } catch (error) {
        console.error("Error encrypting the string:", error.message);
        return null;
    }
};

// Style definitions
const styles = {
    passwordWrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center",
    },
    passwordInput: {
        paddingRight: "2.5rem",
    },
    togglePassword: {
        position: "absolute",
        right: "0.75rem",
        cursor: "pointer",
        background: "none",
        border: "none",
    },
    recaptchaWrapper: {
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
    },
    canvas: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        width: "100vw",
        height: "100vh",
    },
    container: {
        position: "relative",
        zIndex: 1,
    },
    card: {
        background: "rgba(255, 255, 255, 0.85)",
        padding: "0rem",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "450px", // Reduced max width to make the form smaller
        margin: "0 auto",  // Center the form horizontally
    },
    darkBackground: {
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark background instead of blur
        pointerEvents: "none",
    },
    casinoSpinner: {
        border: "8px solid rgba(0, 0, 0, 0.1)",
        borderLeftColor: "#FFD700", // Gold color for casino vibe
        borderTopColor: "#FF4500", // A bright casino-like color
        borderRadius: "50%",
        width: "80px",
        height: "80px",
    },
    loadingOverlay: {
        position: "fixed",  // Ensures it covers the whole viewport
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.7)",  // Slightly darker background, but not fully black
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
};

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false); // Added state for loading
    const navigate = useNavigate();
    const location = useLocation();
    const { connectWebSocket, disconnectWebSocket } = useContext(WebSocketContext);
    const enableRecaptcha = process.env.REACT_APP_ENABLE_RECAPTCHA === "true";
    const canvasRef = useRef(null);
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    // Set document title based on the page (login or other pages)
    useEffect(() => {
        document.title = location.pathname === "/login" ? "Login - Millionaire Club" : "Millionaire Club";
    }, [location.pathname]);

    useEffect(() => {
        let scene, camera, renderer, models = [], rotationSpeeds = [];

        const setupThreeJS = () => {
            setTimeout(() => {
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scene = new THREE.Scene();
                    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
                    renderer = new THREE.WebGLRenderer({ canvas });
                    renderer.setSize(window.innerWidth, window.innerHeight);
                    renderer.setClearColor(0xFFFFF0, 1);  // Golden background for the coin animation

                    // Set up lights
                    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
                    const pointLight = new THREE.PointLight(0xffffff, 1);
                    pointLight.position.set(5, 5, 5);
                    scene.add(ambientLight, pointLight);

                    const totalModels = 15; // Number of coins to be animated

                    for (let i = 0; i < totalModels; i++) {
                        (function (scene) {
                            const loader = new GLTFLoader();
                            loader.load(`${process.env.PUBLIC_URL}/img/scene.gltf`, (gltf) => {
                                const model = gltf.scene;
                                model.scale.set(0.5, 0.5, 0.5);

                                // Random initial position for each model
                                model.position.set(
                                    (Math.random() - 0.5) * 20,
                                    Math.random() * 15 + 10,
                                    (Math.random() - 0.5) * 10
                                );

                                // Random rotation speeds for each model
                                rotationSpeeds.push({
                                    x: Math.random() * 0.02 + 0.01,
                                    y: Math.random() * 0.02 + 0.01,
                                    z: Math.random() * 0.02 + 0.01
                                });

                                models.push(model);
                                scene.add(model);
                            });
                        })(scene); // Pass scene to the IIFE
                    }

                    camera.position.z = 10;

                    const animate = () => {
                        requestAnimationFrame(animate);

                        // Move and rotate each model differently
                        models.forEach((model, index) => {
                            if (model) {
                                // Move the model down
                                model.position.y -= 0.02;
                                if (model.position.y < -5) {
                                    model.position.y = Math.random() * 15 + 10;
                                    model.position.x = (Math.random() - 0.5) * 20;
                                    model.position.z = (Math.random() - 0.5) * 10;
                                }

                                // Rotate the model with unique speeds
                                model.rotation.x += rotationSpeeds[index].x;
                                model.rotation.y += rotationSpeeds[index].y;
                                model.rotation.z += rotationSpeeds[index].z;
                            }
                        });

                        renderer.render(scene, camera);
                    };

                    animate();

                    window.addEventListener("resize", () => {
                        const width = window.innerWidth;
                        const height = window.innerHeight;
                        renderer.setSize(width, height);
                        camera.aspect = width / height;
                        camera.updateProjectionMatrix();
                    });
                }
            }, 100);
        };

        setupThreeJS();

        return () => {
            if (renderer) {
                renderer.dispose();
            }
            window.removeEventListener("resize", () => { });
        };
    }, [canvasRef]);

    useEffect(() => {
        const role = localStorage.getItem("role");
        if (role) {
            navigate(role === "ADMIN" ? "/admin-dashboard" : "/");
        } else {
            setLoading(false);
        }

        const storedUsername = localStorage.getItem("username");
        const storedPassword = localStorage.getItem("password");

        if (storedUsername && storedPassword) {
            setUsername(storedUsername);
            setPassword(storedPassword);
        }

        if (location.state?.successMessage || location.state?.errorMessage) {
            toast.success(location.state.successMessage || location.state.errorMessage, {
                position: "top-right",
                autoClose: 5000,
            });
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

    const validateForm = () => {
        let valid = true;

        if (!username) {
            setUsernameError("Username is required");
            valid = false;
        }

        if (!password) {
            setPasswordError("Password is required");
            valid = false;
        }

        if (enableRecaptcha && !recaptchaValue) {
            toast.error("Please complete the reCAPTCHA verification.", {
                position: "top-right",
                autoClose: 5000,
            });
            valid = false;
        }

        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
    
        setIsSubmitting(true);  // Start loading animation
    
        try {
            const encryptedPassword = encrypt(publicKeyPem, password);
            console.log(encryptedPassword)
    
            const backendResponse = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/v2/user/login`,
                {
                    username,
                    password: encryptedPassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
    
            const { data, code, success } = backendResponse.data;
            if (success && code === 200) {
                const { role, userId } = data;
                localStorage.setItem("role", role);
                localStorage.setItem("userId", userId);
                localStorage.setItem("username", username);
    
                // WebSocket connection should only be made here, after the user is successfully logged in
                await connectWebSocket();
    
                if (["ADMIN", "MASTER", "DISTRIBUTOR", "SUB_DISTRIBUTOR", "STORE"].includes(role)) {
                    document.title = `${role}-Diamond-Casino`;
                    navigate(role === "ADMIN" ? "/admin-dashboard" : "/");
                } else {
                    toast.error("Not authorized", {
                        position: "top-right",
                        autoClose: 5000,
                    });
                }
            } else {
                toast.error("Login failed. Please check with your admin.", {
                    position: "top-right",
                    autoClose: 5000,
                });
            }
        } catch (error) {
            handle403Error(error, navigate, disconnectWebSocket);
        } finally {
            setIsSubmitting(false);  // Stop loading animation once done
        }
    };
    

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        if (e.target.value) {
            setUsernameError(""); // Clear error when user types
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value) {
            setPasswordError(""); // Clear error when user types
        }
    };

    if (loading) {
        return null;
    }

    return (
        <main className="d-flex w-100" style={{ height: "100vh" }}>
            <canvas ref={canvasRef} style={styles.canvas}></canvas> {/* Canvas for the animation */}

            <div className="container">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                    <div className="d-table-cell align-middle">
                        <div className="text-center mt-4">
                            <h1 className="h2">Welcome back!</h1>
                            <p className="lead">Sign in to your account to continue</p>
                        </div>
                        <div className="card" style={styles.card}>
                            <div className="card-body">
                                <div className="m-sm-3">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Username</label>
                                            <input
                                                className="form-control form-control-lg"
                                                type="text"
                                                name="username"
                                                placeholder="Enter your username"
                                                value={username}
                                                onChange={handleUsernameChange}
                                                aria-label="Username"
                                            />
                                            {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <div style={styles.passwordWrapper}>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type={passwordVisibility ? "text" : "password"}
                                                    name="password"
                                                    placeholder="Enter your password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    style={styles.passwordInput}
                                                    aria-label="Password"
                                                />
                                                <button
                                                    type="button"
                                                    style={styles.togglePassword}
                                                    onClick={togglePasswordVisibility}
                                                    aria-label="Toggle password visibility"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={passwordVisibility ? faEyeSlash : faEye}
                                                    />
                                                </button>
                                            </div>
                                            {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                                        </div>
                                        {enableRecaptcha && (
                                            <div style={styles.recaptchaWrapper}>
                                                <ReCAPTCHA
                                                    sitekey={recaptchaSiteKey}
                                                    onChange={setRecaptchaValue}
                                                />
                                            </div>
                                        )}
                                        <div className="d-grid gap-2 mt-3">
                                            <button className="btn btn-lg btn-primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? 'Signing in...' : 'Sign in'}
                                            </button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isSubmitting && (
                <div style={styles.loadingOverlay}>
                    <div className="casino-spinner"></div>
                </div>
            )}

            <style>
                {`
                    .casino-spinner {
                        border: 8px solid rgba(0, 0, 0, 0.1);
                        border-left-color: #FFD700;
                        border-top-color: #FF4500;
                        border-radius: 50%;
                        width: 80px;
                        height: 80px;
                        animation: spinCasino 1.5s ease infinite;
                    }

                    @keyframes spinCasino {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                `}
            </style>
        </main>
    );
};

export default Login;
